<template>
  <div class="main-content">
    <div class="layout-px-spacing mb-5">
      <div class="row layout-top-spacing">
        <div
          class="col-12 col-md-12 col-lg-8 offset-lg-2 layout-spacing bg-white p-5 shadow"
        >
          <form>
            <div class="row mb-4">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="name">Sponsor Name</label>
                  <div
                    class="border border-gray p-2 rounded font-weight-bold text-gray"
                  >
                    {{ onboarding_sponsor.name }}
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="email">Sponsor Email</label>
                  <div
                    class="border border-gray p-2 rounded font-weight-bold text-gray"
                  >
                    {{ onboarding_sponsor.email }}
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="phone">Sponsor Phone</label>
                  <div
                    class="border border-gray p-2 rounded font-weight-bold text-gray"
                  >
                    {{ onboarding_sponsor.phone_number }}
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="location">Date of Registration</label>
                  <div
                    class="border border-gray p-2 rounded font-weight-bold text-gray"
                  >
                    {{ formatFancyDate(onboarding_sponsor.created_at) }}
                  </div>
                </div>
              </div>
              <div
                class="col-md-12 col-lg-12 mt-6 p-3"
                v-if="onboarding_sponsor.onboarding_reminder"
              >
                <div class="card">
                  <div class="card-body bg-success">
                    <h5 class="text-white border-bottom">
                      <i class="fa fa-bell"></i> Reminder
                    </h5>
                    <div class="bg-success font-weight-bold">
                      {{ onboarding_sponsor.onboarding_reminder.note }}
                    </div>
                    <div class="font-weight-bold">
                      Date:
                      {{
                        formatFancyDate(
                          onboarding_sponsor.onboarding_reminder
                            .date_of_reminder
                        )
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-5">
                <hr />

                <div class="row" id="actionBox">
                  <div class="col-12">
                    <div class="form-group">
                      <label for="">New Call Log</label>
                      <textarea
                        class="form-control"
                        v-model="remark"
                        name=""
                        id=""
                        rows="3"
                        :disabled="onboarding_sponsor.is_closed ? true : false"
                      ></textarea>
                    </div>
                  </div>

                  <div class="col-12 text-center mt-3">
                    <button
                      type="button"
                      @click.prevent="saveRemark()"
                      class="btn btn-primary"
                      :disabled="loadingNewRemark"
                      v-if="!onboarding_sponsor.is_closed"
                    >
                      <b-spinner small v-if="loadingNewRemark"></b-spinner>
                      <span v-else>
                        Save <i class="fa fa-paper-plane"></i
                      ></span>
                    </button>
                    <button
                      class="btn btn-primary ml-2"
                      @click.prevent="closeOnboarding()"
                    >
                      <b-spinner
                        small
                        v-if="loadingCloseOnboarding"
                      ></b-spinner>
                      <span v-else>
                        <span v-if="onboarding_sponsor.is_closed"
                          >Open <i class="fa fa-lock-open"></i
                        ></span>
                        <span v-else>Close <i class="fa fa-lock"></i></span>
                      </span>
                    </button>
                    <button
                      class="btn btn-primary ml-2"
                      type="button"
                      data-toggle="modal"
                      data-target="#reminder"
                    >
                      <b-spinner
                        small
                        v-if="loadingCloseOnboarding"
                      ></b-spinner>
                      <span v-else>
                        <span><i class="fa fa-bell"></i></span>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-3">
                <div class="timeline-simple">
                  <p class="timeline-title">Call Logs</p>

                  <div class="mt-3 text-center" v-if="loadingFetchAction">
                    <b-card class="">
                      <b-skeleton animation="wave" width="85%"></b-skeleton>
                      <b-skeleton animation="wave" width="67%"></b-skeleton>
                      <b-skeleton animation="wave" width="70%"></b-skeleton>
                      <b-skeleton animation="wave" width="82%"></b-skeleton>
                      <b-skeleton animation="wave" width="65%"></b-skeleton>
                      <b-skeleton animation="wave" width="90%"></b-skeleton>
                      <b-skeleton animation="wave" width="95%"></b-skeleton>
                    </b-card>
                  </div>

                  <div
                    class="timeline-list my-2"
                    v-for="(
                      remark, index
                    ) in onboarding_sponsor.onboarding_sponsor_logs"
                    :key="index"
                  >
                    <div class="timeline-post-content">
                      <div class="">
                        <h6 class="">
                          {{ formatFancyDate(remark.created_at) }}
                        </h6>
                        <p class="post-text">{{ remark.remark }}</p>
                        <small><b>Added by <span class="text-primary"> {{ remark.username }} </span></b></small>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="reminder"
      role="dialog"
      aria-labelledby="reminder"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="reminder">Set Reminder</h5>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <form method="post" @submit.prevent="saveReminder()">
                  <div class="form-group">
                    <label for="dateSearch">Reminder Note</label>
                    <input
                      class="form-control"
                      v-model="note"
                      id="note"
                      type="text"
                      placeholder="What do you want to be reminded of?"
                    />
                  </div>
                  <div class="form-group">
                    <label for="date">Date of Reminder</label>
                    <input
                      type="date"
                      name="date"
                      id="date"
                      class="form-control"
                      v-model="date"
                    />
                  </div>
                  <button class="btn btn-default m-2" data-dismiss="modal">
                    <i class="fa fa-cancel"></i> Cancel
                  </button>
                  <button type="submit" class="btn btn-primary m-2">
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gerohttp } from "@/utils/gerocarehttp";
import { geroendpoints } from "../../../../utils/endpoints";
import $ from "jquery";

export default {
  name: "OnboardingSponsor",

  data() {
    return {
      loading: false,
      onboarding_sponsor: [],
      remark: "",
      note: "",
      date: "",
      user:"",

      loadingNewRemark: false,
      loadingFetchAction: false,
      loadingCloseOnboarding: false,
    };
  },

  mounted() {
    this.getOnboardingLog();
    this.user = this.$store.state.user
  },

  methods: {
    getOnboardingLog() {
      this.loadingNewRemark = true;
      this.loadingFetchAction = true;

      gerohttp
        .get(
          geroendpoints.FETCH_ONBOARDING_SPONSOR.replace(
            ":id",
            this.$route.params.id
          )
        )
        .then((response) => {
          this.loadingNewRemark = false;
          this.loadingFetchAction = false;

          this.onboarding_sponsor = response;
        })
        .catch((error) => {
          this.loadingNewRemark = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    saveRemark() {
      this.loadingNewRemark = true;
      this.loadingFetchAction = true;

      gerohttp
        .post(
          geroendpoints.SAVE_ONBOARDING_SPONSOR_LOG.replace(
            ":id",
            this.$route.params.id
          ),
          {
            onboarding_sponsor_id: this.$route.params.id,
            remark: this.remark,
            username: this.user.firstname +' '+ this.user.lastname
          }
        )
        .then(() => {
          this.remark = "";
          this.getOnboardingLog();
          this.loadingNewRemark = false;
          this.loadingFetchAction = false;
        })
        .catch((error) => {
          this.loadingNewRemark = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    closeOnboarding() {
      this.loadingCloseOnboarding = true;
      if (this.onboarding_sponsor.onboarding_sponsor_logs.length < 1) {
        this.$toast.error(
          "Enter atleast one call log before you can mark it as closed"
        );
        this.loadingCloseOnboarding = false;
        return;
      }

      gerohttp
        .post(
          geroendpoints.TOGGLE_ONBOARDING_SPONSOR.replace(
            ":id",
            this.$route.params.id
          ),
          {
            onboarding_sponsor_id: this.$route.params.id,
          }
        )
        .then(() => {
          this.getOnboardingLog();
          this.loadingCloseOnboarding = false;
          this.loadingFetchAction = false;
          this.$toast.success(
            "Onboarding sponsor marked as closed successfully"
          );
        })
        .catch((error) => {
          this.loadingCloseOnboarding = false;
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },

    saveReminder() {
      if (this.note.length == 0 || this.date.length == 0) {
        this.$toast.error("The Note and Date field must be provided");
        return;
      }

      gerohttp
        .post(
          geroendpoints.SAVE_ONBOARDING_REMINDER.replace(
            ":id",
            this.$route.params.id
          ),
          {
            note: this.note,
            date_of_reminder: this.date,
            onboarding_sponsor_id: this.$route.params.id,
          }
        )
        .then((response) => {
          $("#reminder").modal("hide");
          this.getOnboardingLog();
          this.$toast.success(response.message);
        })
        .catch(() => {
          this.$toast.error("An error occured, please try again later");
        });
    },
  },
};
</script>

<style
  scoped
  src="@/assets/css/components/timeline/custom-timeline.css"
></style>
<style scoped>
.new-control {
  font-weight: 400;
  font-size: 15px;
}
.tableInput {
  border: none !important;
  font-size: 14px;
}
</style>
